<template>
  <div style="margin: -15px -15px;background-color: #fff;">
    <a-spin :spinning="spinning">
    <div class="videoLib-top">
      <div>
        <span>选择年份：</span>
        <a-date-picker
            v-model="year"
            mode="year"
            v-decorator="['year']"
            placeholder="请选择"
            format="YYYY"
            class="input"
            :open="yearShowOne"
            @openChange="openChangeOne"
            @panelChange="panelChangeOne"/>
      </div>
      <div>
        <span>专家姓名：</span>
        <a-input @pressEnter="lookBtn" placeholder="请输入" class="input" v-model="expertsName"/>
      </div>
      <div>
        <span>视频标题：</span>
        <a-input @pressEnter="lookBtn" placeholder="请输入" class="input" v-model="videoTitle"/>
      </div>
      <div>
        <span>发布状态：</span>
        <a-select
            style="width: 150px"
            class="input"
            @change="stateChange"
            v-model="stateCode"
            placeholder="请选择">
          <a-select-option
              v-for="state in stateCodeList"
              :value="state.state">
            {{state.title}}
          </a-select-option>
        </a-select>
      </div>
      <div>
        <a-button @click="lookBtn" type="primary">查询</a-button>
        <a-button @click="restBtn" style="margin-left: 15px">重置</a-button>
      </div>
    </div>
    <div class="videoLib-content">
        <div class="r-btn">
          <a-button @click="newProject" type="primary">上传视频</a-button>
        </div>
      <div>
        <a-alert type="info" show-icon>
          <template slot="message">
            <span style="cursor: pointer" @click="stateChange('Unpublished')">未发布的视频有<span style="color: #45A5E6;font-weight: 550">{{videoCount}}</span>个</span>
          </template>
        </a-alert>
      </div>
      <div style="margin-top: 15px">
          <a-table :pagination="false" :columns="columns" :data-source="data" :row-key="record => record.id">
            <span slot="name" slot-scope="text, record">
              <a @click="titleBtn(record.mediaUrl)">{{ text }}</a>
            </span>
            <span slot="duration" slot-scope="text, record">
              <span>{{ current_times(text) }}</span>
            </span>
            <span slot="stated" slot-scope="text, record">
              <span style="color: #02ac27;" v-if="text === '已发布'"><admin_con style="color: #02ac27;font-size: 8px" type="icon-yuandian1"/> {{ text }}</span>
              <span  v-if="text === '未发布'"><admin_con style="color: #0E77D1;font-size: 8px" type="icon-yuandian1"/> {{ text }}</span>
            </span>
            <span slot="expertName" slot-scope="text, record">
              <span>{{record.expertName}} - {{record.expertHospital}}</span>
            </span>
            <span slot="action" slot-scope="text, record">
              <a @click="restBtn_table(record)">修改</a>
              <a-popconfirm
                  placement="topRight"
                  style="margin-left: 8px;"
                  title="视频删除后，点播单视频，点播专题，大会单视频都会删除，是否确认删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="confirm(record.id)"
              >
                <a>删除</a></a-popconfirm>
            </span>
          </a-table>
        <div style="width: 100%;margin-top: 15px">
          <div style="float: right;margin-right: 20px">
            <a-pagination
                show-quick-jumper
                :current="pageNum"
                :total="total"
                @change="onChange" />
          </div>
        </div>
        </div>
    </div>
    <!--------------------------------------------------对话框------------------------------------------->
    <a-drawer
        title="上传视频"
        :width="700"
        :visible="visible_upVideo"
        :body-style="{ paddingBottom: '80px' }"
        @close="close_video"
    >
      <a-form-model
          ref="content_ruleForm"
          :model="videoForm"
          :rules="content_video_rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol">
        <a-form-model-item prop="surface_plot" label="封面图">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  id="up_img_surface_plot"
                  style="width: 100%;height:100%;opacity: 0;"
                  accept="image/*"
                  type="file"
                  @change="content_surface_plot_image"
                  ref="content_surface_plot_original_image" />
            </div>
            <div
                class="upload_LiveBroadcastF">
              <img
                  v-if="videoForm.surface_plot"
                  alt=""
                  style="width: 100%;height:100%"
                  :src="videoForm.surface_plot"
              />
            </div>
          </div>
          <div style="color: #a1a1a1;width: 500px">注：建议尺寸1920px*1080px，宽度不能超过1920px，大小不超过2M</div>
        </a-form-model-item>
        <a-form-model-item prop="video" label="视频">
          <div style="display: flex;width: 450px;">
            <div class="uploadImg" >
              <input
                  :disabled="disabledVideo"
                  id="up_video_surface_plot"
                  style="width: 100%;height:100%;opacity: 0;"
                  type="file" accept=".mp4"
                  @change="content_surface_plot_original_video($event)" />
            </div>
            <div
                @click="openVideoUrl(videoForm.video)"
                class="upload_LiveBroadcast">
              <video
                  style="width: 100%;height:100%"
                  :src="videoForm.video"></video>
            </div>
          </div>
          <div style="color: #a1a1a1">注：视频格式为mp4格式</div>
          <div v-if="disabledVideo" style="color: #ff0000">视频上传中...</div>
        </a-form-model-item>
        <a-form-model-item prop="title" label="标题 ">
          <a-input
              v-model="videoForm.title"
              style="width: 370px"
              placeholder="请输入标题"
          />
        </a-form-model-item>
        <a-form-model-item prop="specialist" label="专家">
          <a-select
              @popupScroll="popupScroll_specialist"
              @search="select_specialist"
              v-model="videoForm.specialist"
              show-search
              placeholder="请选择专家"
              option-filter-prop="children"
              style="width: 370px"
          >
            <a-select-option
                v-for="(experts,index) in specialist_experts"
                :key="index"
                :value="experts.id">
              {{experts.name}} - {{experts.hospital}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="片头">
          <a-select
              @popupScroll="popupScroll_start"
              @search="select_start"
              v-model="videoForm.titleStart"
              show-search
              placeholder="请选择"
              option-filter-prop="children"
              style="width: 340px"
          >
            <a-select-option
                v-for="(Starts,index) in startList"
                :key="index"
                :value="Starts.shortId">
              {{Starts.title}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="片尾">
          <a-select
              @popupScroll="popupScroll_end"
              @search="select_end"
              v-model="videoForm.titleEnd"
              show-search
              placeholder="请选择"
              option-filter-prop="children"
              style="width: 340px"
          >
            <a-select-option
                v-for="(ends,index) in endList"
                :key="index"
                :value="ends.shortId">
              {{ends.title}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="isShow">
          <template slot="label">
              <span>大会视频
              <a-tooltip placement="top">
            <template slot="title">
              <span>当大会视频开启时，当前视频自动添加到所选大会下</span>
            </template>
            <a-icon type="exclamation-circle" />
          </a-tooltip></span>
          </template>
          <a-switch
              style="margin-right: 5px"
              v-model="videoForm.conferenceVideoSwitch === 1?true:false"
              checked-children="是"
              un-checked-children="否"
              @click="conferenceVideoSwitchChange(videoForm.conferenceVideoSwitch === 1? 0 : 1)"
              default-checked />
          <!--           @search="select_demandProject"-->
          <a-select
              @popupScroll="popupScroll_conferenceVideo"
              @search="select_conferenceVideo"
              @change="conferenceVideoChange"
              v-if="videoForm.conferenceVideoSwitch"
              v-model="videoForm.conferenceVideo"
              show-search
              placeholder="请选择大会（支持输入搜索）"
              option-filter-prop="children"
              style="width: 290px"
          >
            <a-select-option
                v-for="( conference,index) in conferenceVideoList"
                :key=" conference.id"
                :value=" conference.id">
              {{ conference.title}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="isShow">
          <template slot="label">
              <span>点播专题
              <a-tooltip placement="top">
            <template slot="title">
              <span>当点播专题开启时，当前视频自动添加到所选专题目录下</span>
            </template>
            <a-icon type="exclamation-circle" />
          </a-tooltip></span>
          </template>
          <a-switch
              style="margin-right: 5px"
              v-model="videoForm.demandProjectSwitch === 1?true:false"
              checked-children="是"
              un-checked-children="否"
              @click="demandProjectSwitchChange(videoForm.demandProjectSwitch === 1? 0 : 1)"
              default-checked />
          <!--           @search="select_demandProject"-->
          <a-select
              @popupScroll="popupScroll_demandProject"
              @search="select_demandProject"
              v-if="videoForm.demandProjectSwitch"
              v-model="videoForm.demandProject"
              show-search
              placeholder="请选择专题（支持输入搜索）"
              option-filter-prop="children"
              style="width: 290px"
          >
            <a-select-option
                v-for="(specials,index) in specialsList"
                :key="index"
                :value="specials.id">
              {{specials.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="isShow">
          <template slot="label">
              <span>点播单视频
              <a-tooltip placement="top">
            <template slot="title">
              <span>当点播单视频开启时，当前视频自动上传到所选栏目的app点播模块</span>
            </template>
            <a-icon type="exclamation-circle" />
          </a-tooltip></span>
          </template>
          <a-switch
              style="margin-right: 5px"
              v-model="videoForm.singleVideoSwitch === 1?true:false"
              checked-children="是"
              un-checked-children="否"
              @click="singleVideoSwitchChange(videoForm.singleVideoSwitch === 1? 0 : 1)"
              default-checked />
          <a-select
              mode="multiple"
              v-if="videoForm.singleVideoSwitch"
              v-model="videoForm.singleVideo"
              show-search
              placeholder="请选择点播视频"
              option-filter-prop="children"
              style="width: 290px"
          >
            <a-select-option
                v-for="(columns,index) in columnList"
                :key="index"
                :value="columns.code">
              {{columns.title}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop="include" label="介绍">
          <div style="border: 1px solid #ccc;min-height: 300px;width: 500px">
            <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
            />
            <Editor
                style="overflow-y: hidden;"
                v-model="html"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
            />
          </div>
        </a-form-model-item>
      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button  @click="close_video">
          取消
        </a-button>
        <a-button
            style="margin-left: 10px"
            type="primary" @click="onsubmit_video">
          确定
        </a-button>
      </div>
    </a-drawer>
    </a-spin>
  </div>
</template>
<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import moment from "moment";
import {update} from "../../../utils/update";
import {current_times} from '@/utils/current_time'
import {current_date} from '@/utils/currentDate'
import E from "wangeditor";
import {
  delVideoList, getVideoList,
  get_experts_id,
  get_signature, getSpecialsList,
  get_startEnd,
  get_videoLink, postVideoManage, putVideoManage, getColumnVodsList
} from "@/service/MedicalConference_y";
import axios from "axios";
import ListPackaging from "@/views/content/case/ListPackaging";
import {admin_icon} from "@/utils/myIcon_f";
import {getLiveList} from "@/service/MedicalConference";
import TcVod from "vod-js-sdk-v6";
const columns = [
  {
    title: '标题',
    dataIndex: 'title',
    key: 'title',
    width:'30%',
    scopedSlots: { customRender: 'name' },
  },{
    title: '专家',
    dataIndex: 'expertName',
    key: 'expertName',
    scopedSlots: { customRender: 'expertName' },
  },{
    title: '时长',
    dataIndex: 'duration',
    key: 'duration',
    width:'8%',
    scopedSlots: { customRender: 'duration' },
  },
  {
    title: '上传时间',
    dataIndex: 'createdTime',
    key: 'createdTime',
    width:'15%',
  },
  {
    title: '上传人',
    dataIndex: 'person',
    key: 'person',
    width:'10%',
  },{
    title: '状态',
    dataIndex: 'stated',
    key: 'stated',
    width:'10%',
    scopedSlots: { customRender: 'stated' },
  },{
    title: '操作',
    key: 'action',
    width:'10%',
    scopedSlots: { customRender: 'action' },
  },
];
const editorConfig = { // JS 语法
  MENU_CONF: {}
};
const signature = '';
// 修改 uploadImage 菜单配置
editorConfig.MENU_CONF['uploadImage'] = {
  // 自定义上传
  async customUpload(file, insertFn) {
    let formData = new FormData()
    formData.append('file', file)
    // file 即选中的文件,自己实现上传，并得到图片 url alt href
    axios.post('https://yun-new-admin-api.yunicu.com/api/admin/common/update', formData).then((res) => {
      if(res.data.code === 0){
        insertFn(res.data.data.url)// 最后插入图片
      }
    })
  }
}
// 修改 uploadVideo 菜单配置
editorConfig.MENU_CONF['uploadVideo'] = {
  // 自定义上传
  async customUpload(file, insertFn) {
    let mediaFile = file;
    //获取签名
    axios.get('https://yun-new-admin-api.yunicu.com/v3/getUgcUploadSign').then((res) => {
      if (res.data.code === 0) {
        const getSignature = () => {
          return res.data.data.signature;
        }
        const tcVod = new TcVod({
          getSignature: getSignature //上传签名的函数
        })
        const uploader = tcVod.upload({
          mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
        })
        uploader.done().then(res => {
          insertFn(res.video.url)// 进行视频处理：转码和截图
        })
      }
    })
  }
}
export default {
  name:'videoLibrary',
  components:{
    admin_con: admin_icon, Editor, Toolbar
  },
  data(){
    return{
      current_times,
      spinning:false,
      pageNum:1,
      data:[],
      isShow:true,
      duration:null,
      videoForm:{
        surface_plot:'',
        video:'',
        title:'',
        specialist:undefined,
        include:'',
        titleStart:undefined,
        titleEnd:undefined,
        // singleVideo:undefined,
        singleVideo:[],
        singleVideoSwitch:0,
        demandProject:undefined,
        demandProjectSwitch:0,
        //大会视频
        conferenceVideo:undefined,
        conferenceVideoSwitch:0,
        vType:'',
      },
      content_video_rules:{
        surface_plot: [{ required: true, message: '请上传封面图', trigger: 'blur' }],
        video: [{ required: true, message: '请上传视频', trigger: 'blur' }],
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        specialist: [{ required: true, message: '请输入专家', trigger: 'blur' }],
        include: [{ required: true, message: '请输入介绍', trigger: 'blur' }],
        // titleStart: [{ required: true, message: '请选择片头', trigger: 'blur' }],
        // titleEnd: [{ required: true, message: '请选择片尾', trigger: 'blur' }],
      },
      labelCol: { span: 4},
      wrapperCol: { span: 14 },
      columns,
      videoCount:0,
      visible_upVideo:false,
      yearShowOne:false,
      // year:current_date(),
      year:'',
      expertsName:'',
      videoTitle:'',
      stateCode:'全部',
      //不传查询全部，Published：已发布，Unpublished：未发布
      stateCodeList:[
        {
          title:'全部',
          state:''
        }, {
          title:'未发布',
          state:'Unpublished'
        },{
          title:'已发布',
          state:'Published'
        },
      ],
      editor: null,
      html: '',
      toolbarConfig: { },
      editorConfig,
      mode: 'default', // or 'simple'
      // editor:null,
      // editor_menus:[
      //   'head', // 标题
      //   'bold', // 粗体
      //   'fontSize', // 字号
      //   'fontName', // 字体
      //   'italic', // 斜体
      //   'underline', // 下划线
      //   'strikeThrough', // 删除线
      //   'foreColor', // 文字颜色
      //   'backColor', // 背景颜色
      //   'link', // 插入链接
      //   'list', // 列表
      //   'justify', // 对齐方式
      //   'quote', // 引用
      //   'emoticon', // 表情
      //   'image', // 插入图片
      //   'table', // 表格
      //   'video', // 插入视频
      //   'code', // 插入代码
      //   'undo', // 撤销
      //   'redo', // 重复
      //   'fullscreen' // 全屏
      // ],
      total:0,
      tableId:'',
      vFile:'',
      signature:'',
      Video_fileId:'',
      vUploader: {}, // 腾讯云上传的初始化对象
      specialist_experts:[],
      specialist_page_no:1,
      specialist_title:'',
      startList:[],
      startList_page_no:1,
      startList_title:'',
      endList:[],
      endList_page_no:1,
      endList_title:'',
      columnList:[],
      specialsList:[],
      oldVodId:null,
      specialsList_page_no:1,
      specialsList_title:'',
      manageId:null,
      disabledVideo:false,

      conferenceVideoPageNo:1,
      conferenceVideoPageSize:10,
      conferenceVideoTitle:'',
      conferenceVideoList:[],
      conferenceVideocount:null,

    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle','视频库管理')
    this.getList()
    this.getVideoCount()
    // console.log(this.current_times(62))
  },
  methods:{
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    //获取列表
    async getList() {
      this.spinning = true
      let data = {
        title:this.videoTitle,
        expertName:this.expertsName,
        // year:this.year.slice(0,4),
        year:this.year,
        stated:this.stateCode,
        page_no:this.pageNum,
        page_size:10,
      }
      const response = await getVideoList(data)
      if(response.code === 0){
        this.data = response.data
        this.total = response.count
      }else {
        this.$message.warning(response.message)
      }
      this.spinning = false
    },
    //获取未添加的视频
    async getVideoCount() {
      let data = {
        stated:'Unpublished',
        page_no:1,
        page_size:10,
      }
      const response = await getVideoList(data)
      if(response.code === 0){
        this.videoCount = response.count
      }
    },
    //获取专家列表
    async getExpertsId(name,pageNum,pageSize) {
      const response = await get_experts_id(name,pageNum,pageSize)
      if(response.code === 0){
        let list  = response.data
        for (let i=0;i<list.length;i++){
          this.specialist_experts.push({
            id: list[i].id,
            name: list[i].name,
            hospital: list[i].hospital,
          })
        }
      }else {
        this.$message.warning(response.message)
      }
    },
    //滚动条监听
    popupScroll_specialist(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.specialist_page_no++
        this.getExpertsId(this.specialist_title,this.specialist_page_no)
      }
    },
    //鼠标搜索
    select_specialist(value){
      //首先清空数组，否则会数据重复
      this.specialist_experts = []
      this.specialist_title = value
      this.getExpertsId(value,1)
    },
    //获取片头
    async getStartList(pageNum,pageSize,type,title) {
      const response = await get_startEnd(type,pageNum,pageSize,title)
      if(response.code === 0){
        let list  = response.data
        for (let i=0;i<list.length;i++){
          this.startList.push({
            shortId: list[i].shortId,
            title: list[i].title,
          })
        }

      }else {
        this.$message.warning(response.message)
      }
    },
    //滚动条监听
    popupScroll_start(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.startList_page_no++
        this.getStartList(this.startList_page_no,10,'start',this.startList_title)
      }
    },
    //鼠标搜索
    select_start(value){
      //首先清空数组，否则会数据重复
      this.startList = []
      this.startList_title = value
      this.getStartList(1,10,'start',value)
    },
    //获取片尾
    async getEndList(pageNum,pageSize,type,title) {
      const response = await get_startEnd(type,pageNum,pageSize,title)
      if(response.code === 0){
        let list  = response.data
        for (let i=0;i<list.length;i++){
          this.endList.push({
            shortId: list[i].shortId,
            title: list[i].title,
          })
        }
      }else {
        this.$message.warning(response.message)
      }
    },
    //滚动条监听
    popupScroll_end(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.endList_page_no++
        this.getEndList(this.endList_page_no,10,'end',this.endList_title)
      }
    },
    //鼠标搜索
    select_end(value){
      //首先清空数组，否则会数据重复
      this.endList = []
      this.endList_title = value
      this.getEndList(1,10,'end',value)
    },
    //获取点播单视频 --- 栏目
    async getcolumnsList() {
      this.columnList = []
      const response = await getColumnVodsList()
      if(response.code === 0){
        // this.columnList = response.data
        for (let i=0;i<response.data.length;i++){
          if(response.data[i].code !== 'YUN_ICU'){
            this.columnList.push({
              code: response.data[i].code,
              delStatus: response.data[i].delStatus,
              id: response.data[i].id,
              title:response.data[i].title
            })
          }
        }
      }else {
        this.$message.warning(response.message)
      }
    },
    // 点播专题
    async getspecialsList() {
      let data = {
        title:this.specialsList_title,
        page_no:this.specialsList_page_no,
        page_size:10,
        //与大会视频关联，若选择大会视频，点播专题会重新加载为大会视频下的专题列表
        conventionId:this.videoForm.conferenceVideo
      }
      const response = await getSpecialsList(data)
      if(response.code === 0){
        let list  = response.data
        for (let i=0;i<list.length;i++){
          this.specialsList.push({
            id: list[i].id,
            name: list[i].title,
          })
        }
      }else {
        this.$message.warning(response.message)
      }
    },
    //滚动条监听
    popupScroll_demandProject(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.specialsList_page_no++
        // this.getspecialsList(this.specialsList_page_no,this.specialsList_title)
        this.getspecialsList()
      }
    },
    select_demandProject(value){
      this.specialsList_page_no = 1
      this.specialsList = []
      this.specialsList_title = value
      this.getspecialsList()
    },
    // 点播专题
    async getConferenceVideo() {
      //判断获取到的总条数 = count时，不执行get接口
      if(this.conferenceVideocount !== this.conferenceVideoList.length){
        let data = {
          page_no:1,
          page_size:this.conferenceVideoPageSize,
          title:this.conferenceVideoTitle,
        }
        const response = await getLiveList(data)
        if(response.code === 0){
          this.conferenceVideoList = response.data
          this.conferenceVideocount = response.count
        }else {
          this.$message.warning(response.message)
        }
      }else {
        console.log('到底了')
      }
    },
    //滚动条监听
    popupScroll_conferenceVideo(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.conferenceVideoPageNo++
        this.conferenceVideoPageSize = this.conferenceVideoPageNo * this.conferenceVideoPageSize
        this.getConferenceVideo()
      }
    },
    //搜索监听
    select_conferenceVideo(value){
      //首先清空数组，否则会数据重复
      this.conferenceVideoList = []
      this.conferenceVideoPageNo = 1
      this.conferenceVideocount = null
      this.conferenceVideoTitle = value
      this.getConferenceVideo()
    },
    //当大会选择的值发生改变时
    conferenceVideoChange(value){
      this.videoForm.conferenceVideo = value
      this.specialsList = []
      this.getspecialsList()
    },


    //富文本编辑器
    async create_editor_config() {
      //等待DOM加载完毕
      const editor = new E(this.$refs.editor)
      this.editor = editor
      //去除图片视频上传
      editor.config.excludeMenus = [
        'image',
        'video'
      ]
      // 设置编辑区域高度为 500px
      editor.config.height = 200
      //创建编辑器
      await editor.create()
    },
    onChange(pageNumber){
      this.pageNum = pageNumber
      this.getList()
    },
    restBtn_table(row) {
      this.html = '';
      this.onCreated();
      setTimeout(() => {
        this.html = row.remark;
      }, 100)
      this.getConferenceVideo();
      this.tableId = row.id;

      let form = this.videoForm;
      form.surface_plot = row.coverImg;
      form.title = row.title;
      form.specialist = row.expertId;

      form.titleStart = row.videoStartId;
      form.titleEnd = row.videoEndId;

      this.Video_fileId = row.fileId;
      form.video = row.mediaUrl;
      this.duration = row.duration;
      this.specialist_title = row.expertName;
      this.getExpertsId(row.expertName);
      //片头
      this.getStartList(1,10,'start');
      //片尾
      this.getEndList(1,10,'end');

      this.isShow = false;
      this.visible_upVideo = true;
      // this.$nextTick(() => {
      //   if (this.editor === null) {
      //     this.create_editor_config()
      //   } else {
      //     this.editor.destroy()
      //     this.create_editor_config()
      //   }
      //   if(row.remark){
      //     this.editor.txt.html(row.remark)
      //   }
      // })
    },

    // async demandProjectChange() {
    //   //修改的情况下
    //   if(this.tableId){
    //     const response = await put_video_topic(this.oldVodId,this.videoForm.demandProject)
    //     if (response.code === 0) {
    //     } else {
    //       this.$message.warning(response.message)
    //     }
    //   }
    // },
    // 弹出日历和关闭日历的回调
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }else {
        this.yearShowOne = false;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearShowOne = false;
      this.year = value;
      if(this.year){
        this.year = moment(this.year).format('YYYY')
      }
      this.pageNum = 1
      this.getList()
    },
    lookBtn(){
      this.getList()
    },
    restBtn(){
      // this.year = current_date()
      this.year = ''
      this.expertsName = ''
      this.pageNum = 1
      this.videoTitle = ''
      this.stateCode = ''
      this.getList()
    },

    newProject(){
      this.html = '';
      this.onCreated();

      this.isShow = true

      this.getExpertsId()
      //片头
      this.getStartList(this.startList_page_no,10,'start')
          //片尾
      this.getEndList(this.endList_page_no,10,'end')

          //点播单视频----栏目
      this.getcolumnsList()
      //点播专题
      this.getspecialsList()
      this.getConferenceVideo()
      this.visible_upVideo = true
      // this.$nextTick(() => {
      //   if(this.editor === null){
      //     this.create_editor_config()
      //   }else {
      //     this.editor.destroy()
      //     this.create_editor_config()
      //   }
      // })
    },
    titleBtn(mediaUrl) {
      window.open(mediaUrl)
    },
    table_restBtn(id){

    },
    table_delBtn(id){

    },
    async confirm(id) {
      const response = await delVideoList(id)
      if (response.code === 0) {
        this.$message.success("删除成功~")
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    },
    close_video(){
      this.visible_upVideo = false
      this.disabledVideo = false
      // this.videoForm.video = ''
      this.videoForm.demandProjectSwitch = 0
      this.videoForm.conferenceVideoSwitch = 0
      this.videoForm.demandProject = undefined
      this.videoForm.conferenceVideo = undefined
      this.videoForm.singleVideoSwitch = 0
      // this.videoForm.singleVideo = undefined
      this.videoForm.singleVideo = []
      this.videoForm.titleStart = undefined
      this.videoForm.titleEnd = undefined
      this.Video_fileId = ''
      this.tableId = null
      this.oldVodId = null
      this.manageId = null
      //专家select
      this.specialist_title = ''

      //专家 select
      this.specialist_page_no = 1
      this.specialist_experts = []

      //专题 select
      this.specialsList_page_no = 1
      this.specialsList = []
      //片头片尾
      this.startList = []
      this.endList = []
      this.startList_page_no = 1
      this.endList_page_no = 1

      this.$refs.content_ruleForm.resetFields();
    },
    // 上传腾讯云
    uploadVideoFile_toyun(){
      this.disabledVideo = true
      let _this = this
      const getSignature = () => {
        return _this.signature;
      }
      const tcVod = new _this.$TcVod({
        getSignature: getSignature // 前面的获取上传签名的函数
      })
      const mediaFile = this.vFile
      this.vUploader = tcVod.upload({
        mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
      })
      this.vUploader.done().then(res => { // 上传完成回调
        //视频上传后获取到的视频fileId
        this.Video_fileId = res.fileId;
        //获取视频url地址
        this.content_video_url(res.fileId)
        this.$message.success('视频文件上传成功')
      })
    },
    async content_video_url(videoId) {
      this.disabledVideo = true;
      const response = await get_videoLink(videoId)
      if (response.code === 0) {
        this.videoForm.video = response.data.mediaUrl

        let audio = new Audio(response.data.mediaUrl)
        let self = this
        audio.addEventListener("loadedmetadata",function (e){
          self.duration = parseInt(audio.duration)
        })
      }else {
        this.$message.warning(response.message)
      }
      this.disabledVideo = false;
    },
    //获取签名
    async content_surface_plot_getSignature() {
      this.disabledVideo = true
      const response = await get_signature()
      if (response.code === 0) {
        this.signature = response.data.signature;
      }
      // console.log(this.disabledVideo)
    },
    async content_surface_plot_original_video(e) {
      if (e.currentTarget.files[0]) { // 选择了文件
        if (e.currentTarget.files[0].type === 'video/mp4') {
          this.vFile = e.currentTarget.files[0] // 获取上传文件中的File对象信息

          await this.content_surface_plot_getSignature()
          await this.uploadVideoFile_toyun()
        } else {
          this.$message.warning('仅支持mp4格式的视频上传')
        }
      } else { // 取消选择文件
        this.vFile = {}
        this.vIsFileExist = false
      }
      document.getElementById('up_video_surface_plot').value = null;
    },
    openVideoUrl(url){
      window.open(url)
    },
    async content_surface_plot_image() {
      let _this = this
      let form = this.videoForm
      let inputDOM = this.$refs.content_surface_plot_original_image.files[0];
      //判断图片大小
      if (inputDOM.size < 2097152) {

        //判断图片尺寸
        if (inputDOM) {
          var reader = new FileReader()
          reader.onload = function (event) {
            var base64 = event.target.result
            var img = document.createElement('img')
            img.src = base64
            img.onload = async function () {
              if (img.width <= 1920) {
                const image = await update(inputDOM)
                if (image.code === 0) {
                  form.surface_plot = image.data.url
                  _this.$message.success("上传成功")
                } else {
                  _this.$message.error(image.message)
                }
              } else {
                _this.$message.warning("注:建议尺寸1920px*1080px，宽度不能超过1920px !")
              }
            }
          }
          reader.readAsDataURL(inputDOM)
        }
      } else {
        this.$message.warning("请上传小于2M的图片！")
      }
      document.getElementById('up_img_surface_plot').value = null;
    },
    singleVideoSwitchChange(isOpen){
      this.videoForm.singleVideoSwitch = isOpen
    },
    demandProjectSwitchChange(isOpen){
      this.videoForm.demandProjectSwitch = isOpen
    },
    conferenceVideoSwitchChange(isOpen){
      if(isOpen === 0){
        this.videoForm.conferenceVideo = undefined
        this.specialsList = []
        this.getspecialsList()
      }
      this.videoForm.conferenceVideoSwitch = isOpen
    },
    onsubmit_video(){
      this.videoForm.include = this.html;
      this.$refs.content_ruleForm.validate(valid =>{
        if(valid){
          let form = this.videoForm
          if(this.tableId){
          //  修改
            let data = {
              "id": this.tableId,
              "coverImg": form.surface_plot,
              "title": form.title,
              "fileId": this.Video_fileId,
              "mediaUrl": form.video,
              "coverUrl": form.surface_plot,
              "duration": this.duration,
              "expertId":  form.specialist,
              "remark": form.include,
              "videoStartId":  form.titleStart ,
              "videoEndId":  form.titleEnd ,
              //"是否有片头  1是   0否"
              "isVideoStart": form.titleStart ? 1:0,
              //"是否有片尾  1是   0否"
              "isVideoEnd": form.titleEnd ? 1:0,
            }
            this.restList(data)
          }else {
            let dataAdd = {
              "coverImg": form.surface_plot,
              "title": form.title,
              "fileId": this.Video_fileId,
              "mediaUrl": form.video,
              "coverUrl": form.surface_plot,
              "duration": this.duration,
              "expertId": form.specialist,
              "remark": form.include,
              "videoStartId":  form.titleStart,
              "videoEndId": form.titleEnd,
              //"是否有片头  1是   0否"
              "isVideoStart": form.titleStart ? 1:0,
              //"是否有片尾  1是   0否"
              "isVideoEnd": form.titleEnd ? 1:0,

              // //"是否点播单视频  1是   0否"
              // "isSingle": form.singleVideoSwitch,
              // "singleColumnCode": form.singleVideo,
              //"是否点播专题  1是   0否"
              // "isTopic": form.demandProjectSwitch,
              // "topicId": form.demandProject,

              //大会视频
              "conventionId":form.conferenceVideoSwitch && form.conferenceVideo ? form.conferenceVideo : null,
              // 点播专题
              "topicId": form.demandProjectSwitch && form.demandProject ? form.demandProject : null,
              //  栏目
              "columnList": form.singleVideoSwitch && form.singleVideo.length ? form.singleVideo : null
            }
            // if(dataAdd.column_code === undefined){
            //   dataAdd.column_code = ""
            // }
            this.addTable(dataAdd)

          }
          this.visible_upVideo = false;

          this.videoForm.demandProject = undefined
          this.videoForm.singleVideo = []
          this.videoForm.demandProjectSwitch = 0
          this.videoForm.singleVideoSwitch = 0
          this.videoForm.titleStart = undefined
          this.videoForm.titleEnd = undefined
          this.videoForm.conferenceVideoSwitch = 0
          this.videoForm.conferenceVideo = []
          this.Video_fileId = ''
          this.tableId = null
          this.manageId = null
          this.oldVodId = null
          //专家 select
          this.specialist_title = ''
          this.specialist_page_no = 1
          this.specialist_experts = []

          //专题 select
          this.specialsList_title = ''
          this.specialsList_page_no = 1
          this.specialsList = []
          //片头片尾
          this.startList = []
          this.endList = []
          this.startList_page_no = 1
          this.endList_page_no = 1;
          this.html = '';

          this.$refs.content_ruleForm.resetFields();
        }else {
          this.$message.warning("表单有空~")
          return false;
        }
      })

    },
    //新增
    async addTable(data) {
      const response = await postVideoManage(data)
      if (response.code === 0) {
        this.$message.success("操作成功~")
        this.pageNum = 1
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    },
    //修改
    async restList(data) {
      const response = await putVideoManage(data)
      if (response.code === 0) {
        this.$message.success("修改成功~")
        await this.getList()
      } else {
        this.$message.warning(response.message)
      }
    },
    stateChange(value){
      this.stateCode = value
      this.pageNum = 1
      this.getList()
    },
    // current_times(time) {
    //   // 转换为式分秒
    //   let h = parseInt(time / 60 / 60 % 24)
    //   h = h < 10 ? '0' + h : h
    //   let m = parseInt(time / 60 % 60)
    //   m = m < 10 ? '0' + m : m
    //   let s = parseInt(time % 60)
    //   s = s < 10 ? '0' + s : s
    //   // 作为返回值返回
    //   return h + ':' + m + ':' + s
    // }
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
.videoLib-top{
  width: 100%;
  border-bottom: 13px solid #f0f2f5;
  height: 100px;
  display: flex;
  padding-top: 2%;
  justify-content: space-around;
  .input{
    width:60%;
    margin-left: 10px;
  }
}
.videoLib-content{
  min-height: 600px;
  width: 97%;
  margin: auto;
    .r-btn{
      padding-top: 20px;
      padding-bottom: 15px;
    }
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  background-image: url("../../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_LiveBroadcast{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  margin-left: 20px;
  text-align: center;
}.upload_LiveBroadcastF{
  border: #DDDDDD 2px dashed;
  width: 240px;
  height: auto;
  margin-left: 20px;
  text-align: center;
}
</style>

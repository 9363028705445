export function current_times(time) {
    // 转换为式分秒
    let h = parseInt(time / 60 / 60 % 24)
    h = h < 10 ? '0' + h : h
    let m = parseInt(time / 60 % 60)
    m = m < 10 ? '0' + m : m
    let s = parseInt(time % 60)
    s = s < 10 ? '0' + s : s
    // 作为返回值返回
    return h + ':' + m + ':' + s
}
export function current_times_min(time) {
    // 将秒转换为分钟并向下取整
    var seconds = time; // 两分钟的秒数
    var minutes = Math.floor(seconds / 60); // 将秒转换为分钟并向下取整
    return minutes
}
